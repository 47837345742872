import { Mail } from "tabler-icons-react";
export default function NewsLetter() {
  return (
    <section className="w-full bg-[#2f2b3a]/20 flex items-center justify-center">
      <div className="py-12 px-4 container lg:py-32 lg:px-6 ">
        <div className="max-w-screen-md mx-auto sm:text-center">
          <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-primary md:text-6xl lg:text-7xl ">
            Newsletter
          </h1>
          <p className="text-lg font-normal text-gray-400 lg:text-xl ">
            Stay up to date with our latest news and products.
          </p>
          <form action="#" className="mt-10">
            <div className="items-center mx-auto mb-3 space-y-4 max-w-screen-sm sm:flex sm:space-y-0">
              <div className="relative w-full">
                <label
                  htmlFor="email"
                  className="hidden mb-2 text-sm font-medium text-gray-900 "
                >
                  Email address
                </label>
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <Mail className="w-5 h-5 text-gray-500 " />
                </div>
                <input
                  className="block p-3 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:rounded-none sm:rounded-l-lg focus:ring-primary-500 focus:border-primary-500 "
                  placeholder="Enter your email"
                  type="email"
                  id="email"
                  required
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="py-3 px-5 w-full text-sm font-medium text-center text-white rounded-lg border cursor-pointer bg-primary  sm:rounded-none sm:rounded-r-lg hover:bg-primary/90 "
                >
                  Subscribe
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
