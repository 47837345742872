import Section from "./section";
import { ArrowNarrowRight } from "tabler-icons-react";

export default function OurProducts() {
  return (
    <div className="w-full flex flex-col items-center justify-center">
      <div className="w-full px-4 flex flex-col items-center mb-12">
        <h2 className="text-3xl lg:text-6xl  font-bold font-display text-base-100">
          Our Product
        </h2>

        <div className="h-1 bg-gradient-to-r from-[#00A0E8] to-primary w-full max-w-[500px] my-10 rounded-full">
          <div className="h-0.5 bg-gradient-to-r from-[#00A0E8] to-primary  w-full max-w-[500px] blur-3xl rounded-full"></div>
        </div>
      </div>

      <div className="flex md:flex-row flex-col w-full items-center justify-between gap-10 px-4">
        <div className="flex items-start justify-center gap-2 flex-col md:w-1/3 w-full p-6 bg-[#2f2b3a]/20 rounded-2xl h-[460px]">
          <img
            src="./images/products/hammer.svg"
            alt=""
            className="w-full h-full object-cover"
          />
          <div className="flex flex-col items-start text-start gap-2">
            <h1 className="text-2xl font-bold tracking-tight leading-none text-gray-100">
              Claw Hammer
            </h1>
            <p className="text-lg font-normal text-gray-400">
              High-Carbon Steel
            </p>
          </div>
          <a
            href="#qrcode"
            className="inline-flex w-full justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary hover:bg-primary/90 focus:ring-4 focus:ring-primary-300"
          >
            Buy Now
            <ArrowNarrowRight className="ml-2 -mr-1 w-5 h-5" />
          </a>
        </div>
        <div className="flex items-start justify-center gap-2 flex-col md:w-1/3 w-full p-6 bg-[#2f2b3a]/20 rounded-2xl h-[460px]">
          <img
            src="./images/products/light.svg"
            alt=""
            className="w-full h-full object-fill"
          />
          <div className="flex flex-col items-start text-start gap-2">
            <h1 className="text-2xl font-bold tracking-tight leading-none text-gray-100">
              LED Panel Light
            </h1>
            <p className="text-lg font-normal text-gray-400">18W</p>
          </div>
          <a
            href="#qrcode"
            className="inline-flex w-full justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary hover:bg-primary/90 focus:ring-4 focus:ring-primary-300"
          >
            Buy Now
            <ArrowNarrowRight className="ml-2 -mr-1 w-5 h-5" />
          </a>
        </div>
        <div className="flex items-start justify-center gap-2 flex-col md:w-1/3 w-full p-6 bg-[#2f2b3a]/20 rounded-2xl h-[460px]">
          <img
            src="./images/products/tool.svg"
            alt=""
            className="w-full h-full object-cover"
          />
          <div className="flex flex-col items-start text-start gap-2">
            <h1 className="text-2xl font-bold tracking-tight leading-none text-gray-100">
              Professional Tools
            </h1>
            <p className="text-lg font-normal text-gray-400">
              Double color heavy duty insulated handle
            </p>
          </div>
          <a
            href="#qrcode"
            className="inline-flex w-full justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary hover:bg-primary/90 focus:ring-4 focus:ring-primary-300"
          >
            Buy Now
            <ArrowNarrowRight className="ml-2 -mr-1 w-5 h-5" />
          </a>
        </div>
      </div>
      <div className="flex md:flex-row flex-col w-full items-center justify-between gap-10 px-4 mt-10">
        <div className="flex items-start justify-center gap-2 flex-col md:w-1/3 w-full p-6 bg-[#2f2b3a]/20 rounded-2xl h-[460px]">
          <img
            src="./images/products/lamp.svg"
            alt=""
            className="w-full flex-1 object-cover"
          />
          <div className="flex flex-col items-start text-start gap-2">
            <h1 className="text-2xl font-bold tracking-tight leading-none text-gray-100">
              LED Fluorescent Lamp
            </h1>
            <p className="text-lg font-normal text-gray-400">
              Long Life 10000 Hours
            </p>
          </div>
          <a
            href="#qrcode"
            className="inline-flex w-full justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary hover:bg-primary/90 focus:ring-4 focus:ring-primary-300"
          >
            Buy Now
            <ArrowNarrowRight className="ml-2 -mr-1 w-5 h-5" />
          </a>
        </div>
        <div className="flex items-start justify-center gap-2 flex-col md:w-1/3 w-full p-6 bg-[#2f2b3a]/20 rounded-2xl h-[460px]">
          <img
            src="./images/header.svg"
            alt=""
            className="w-full flex-1 max-h-[290px] "
          />
          <div className="flex flex-col items-start text-start gap-2">
            <h1 className="text-2xl font-bold tracking-tight leading-none text-gray-100">
              Ventilating Fan
            </h1>
            <p className="text-lg font-normal text-gray-400">40W | 4inch</p>
          </div>
          <a
            href="#qrcode"
            className="inline-flex w-full justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary hover:bg-primary/90 focus:ring-4 focus:ring-primary-300"
          >
            Buy Now
            <ArrowNarrowRight className="ml-2 -mr-1 w-5 h-5" />
          </a>
        </div>
        <div className="flex items-start justify-center gap-2 flex-col md:w-1/3 w-full p-6 bg-[#2f2b3a]/20 rounded-2xl h-[460px]">
          <img
            src="./images/products/compressor.svg"
            alt=""
            className="w-full flex-1 object-cover"
          />
          <div className="flex flex-col items-start text-start gap-2">
            <h1 className="text-2xl font-bold tracking-tight leading-none text-gray-100">
              Mini Compressor D'AIR
            </h1>
            <p className="text-lg font-normal text-gray-400">150PSI</p>
          </div>
          <a
            href="#qrcode"
            className="inline-flex w-full justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary hover:bg-primary/90 focus:ring-4 focus:ring-primary-300"
          >
            Buy Now
            <ArrowNarrowRight className="ml-2 -mr-1 w-5 h-5" />
          </a>
        </div>
      </div>
      <div className="flex w-full items-center justify-center mt-10 px-4">
        <div className="flex md:flex-row flex-col w-full items-center justify-between gap-16 bg-[#2f2b3a]/20 rounded-2xl md:p-10 p-6">
          <div className="md:w-2/5 w-full flex flex-col items-start text-start gap-6">
            <div className="flex flex-col items-start text-start gap-2">
              <h1 className="text-2xl font-bold tracking-tight leading-none text-gray-100">
                Aspirator Blower
              </h1>
              <p className="text-lg font-normal text-gray-400">
                A Lea blower / vacuum is a versatile tool that can be used for
                blowing leaves, cleaning gutters, and vacuuming up debris.
              </p>
            </div>
            <a
              href="#qrcode"
              className="inline-flex md:w-72 w-full justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary hover:bg-primary/90 focus:ring-4 focus:ring-primary-300"
            >
              Buy Now
              <ArrowNarrowRight className="ml-2 -mr-1 w-5 h-5" />
            </a>
          </div>
          <img
            src="./images/products/blower.svg"
            alt=""
            className="flex-1 object-cover md:w-3/5 w-full"
          />
        </div>
      </div>
      <div className="flex md:flex-row flex-col w-full items-center justify-between gap-10 px-4 mt-10">
        <div className="flex items-start justify-center gap-2 flex-col md:w-1/3 w-full p-6 bg-[#2f2b3a]/20 rounded-2xl h-[460px]">
          <img
            src="./images/products/led.svg"
            alt=""
            className="w-full flex-1 object-cover"
          />
          <div className="flex flex-col items-start text-start gap-2">
            <h1 className="text-2xl font-bold tracking-tight leading-none text-gray-100">
              Professional LED Pojector
            </h1>
            <p className="text-lg font-normal text-gray-400">50W</p>
          </div>
          <a
            href="#qrcode"
            className="inline-flex w-full justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary hover:bg-primary/90 focus:ring-4 focus:ring-primary-300"
          >
            Buy Now
            <ArrowNarrowRight className="ml-2 -mr-1 w-5 h-5" />
          </a>
        </div>
        <div className="flex items-start justify-center gap-2 flex-col md:w-1/3 w-full p-6 bg-[#2f2b3a]/20 rounded-2xl h-[460px]">
          <img
            src="./images/products/pump.svg"
            alt=""
            className="w-full flex-1 object-cover"
          />
          <div className="flex flex-col items-start text-start gap-2">
            <h1 className="text-2xl font-bold tracking-tight leading-none text-gray-100">
              Multifunction Submersible pump
            </h1>
            <p className="text-lg font-normal text-gray-400">100% Copper</p>
          </div>
          <a
            href="#qrcode"
            className="inline-flex w-full justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary hover:bg-primary/90 focus:ring-4 focus:ring-primary-300"
          >
            Buy Now
            <ArrowNarrowRight className="ml-2 -mr-1 w-5 h-5" />
          </a>
        </div>
        <div className="flex items-start justify-center gap-2 flex-col md:w-1/3 w-full p-6 bg-[#2f2b3a]/20 rounded-2xl h-[460px]">
          <img
            src="./images/products/waier-led.svg"
            alt=""
            className="w-full flex-1 object-cover"
          />
          <div className="flex flex-col items-start text-start gap-2">
            <h1 className="text-2xl font-bold tracking-tight leading-none text-gray-100">
              Professional LED{" "}
            </h1>
            <p className="text-lg font-normal text-gray-400">
              2-LING--160W 50M{" "}
            </p>
          </div>
          <a
            href="#qrcode"
            className="inline-flex w-full justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary hover:bg-primary/90 focus:ring-4 focus:ring-primary-300"
          >
            Buy Now
            <ArrowNarrowRight className="ml-2 -mr-1 w-5 h-5" />
          </a>
        </div>
      </div>
    </div>
  );
}
