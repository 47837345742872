import { ArrowNarrowRight } from "tabler-icons-react";

export default function Navbar() {
  return (
    <nav>
      <div className="flex flex-wrap justify-between items-center mx-auto p-4">
        <a href="/" className="flex items-center">
          <img src="./images/logo.svg" className="h-10 mr-3" alt="Logo" />
        </a>
        <div className="flex items-center">
          <a
            href="#qrcode"
            className="inline-flex  justify-center items-center py-3 px-5 text-base font-medium text-center text-primary hover:text-white rounded-full border-primary border-2 hover:bg-primary/90 focus:ring-4 focus:ring-primary-300"
          >
            Contact Sales
            <ArrowNarrowRight className="ml-2 -mr-1 w-5 h-5" />
          </a>
        </div>
      </div>
    </nav>
  );
}
