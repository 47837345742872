import React from "react";
import Header from "./components/header";
import Footer from "./components/footer";
import TopSelling from "./components/top-selling";
import Navbar from "./components/navbar";
import OurProducts from "./components/products";
import NewsLetter from "./components/news";
import QrSection from "./components/qr-section";

function App() {
  return (
    <div className="flex flex-col items-center justify-center w-full   min-h-screen relative bg-[#121212]">
      <div className="absolute top-0 left-0 w-full object-fill h-[1000px] z-0 bg-[#2f2b3a]/20 lg:rounded-bl-[600px] "></div>
      <div className="container z-10">
        <Navbar />
        <div className="h-32"></div>
        <Header />
        <div className="h-96"></div>
        <div className="h-44"></div>
        <TopSelling />
        <div className="h-44"></div>
        <OurProducts />
        <div className="h-44"></div>
        <QrSection />
        <div className="h-44"></div>
      </div>
      <NewsLetter />
      <div className="h-44"></div>
      <Footer />
    </div>
  );
}

export default App;
