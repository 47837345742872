import { motion } from "framer-motion";

import { ArrowNarrowRight } from "tabler-icons-react";
import AnimatedTextWord from "./text-animation";
export default function Header() {
  return (
    <section className="z-10 flex md:flex-row flex-col items-center justify-between gap-8 px-4">
      <div className="py-8 px-4 mx-auto max-w-screen-xl text-start lg:py-16 lg:px-12 md:w-3/5 w-full ">
        <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-primary md:text-6xl lg:text-7xl ">
          <AnimatedTextWord text="VENTILATING FAN" />
        </h1>
        <p className="text-lg font-normal text-gray-400 lg:text-xl md:w-5/6 w-full">
          This ball-bearing ventilating fan is quiet and efficient, making it
          ideal for areas where noise is a concern. It is also made of 100%
          copper winding for long-lasting performance.
        </p>
        <div className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row justify-start sm:space-y-0 sm:space-x-4 mt-6">
          <a
            href="#qrcode"
            className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary hover:bg-primary/90 focus:ring-4 focus:ring-primary-300 "
          >
            Buy Now
            <ArrowNarrowRight className="ml-2 -mr-1 w-5 h-5" />
          </a>
        </div>
      </div>
      <img src="./images/header.svg" alt="" className="md:w-2/5 w-full" />
    </section>
  );
}
