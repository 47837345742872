export default function QrSection() {
  return (
    <div className="flex flex-col items-center justify-center" id="qrcode">
      <div className="w-full px-4 flex flex-col items-center mb-12">
        <h2 className="text-3xl lg:text-5xl rtl:lg:text-5xl font-medium font-display mb-4 text-base-100">
          Scan QR Code to Purchase Products
        </h2>
        <p className="text-lg font-normal text-gray-400 lg:text-xl md:w-2/3 w-full text-center">
          Our website is the best way to learn more about our company, our
          products, and our services. Scan this QR code with your smartphone to
          chat with us and get started today!{" "}
        </p>
        <div className="h-1 bg-gradient-to-r from-[#00A0E8] to-primary w-full max-w-[500px] my-10 rounded-full">
          <div className="h-0.5 bg-gradient-to-r from-[#00A0E8] to-primary  w-full max-w-[500px] blur-3xl rounded-full"></div>
        </div>
      </div>
      <div className="px-4">
        <img
          src="./images/qr-code.jpeg"
          alt="qr-code"
          className=" max-w-96 w-72 max-h-96  h-72 object-fill"
        />
      </div>
    </div>
  );
}
