import { BrandWhatsapp, BrandWechat, Mail } from "tabler-icons-react";

export default function Footer() {
  return (
    <footer className="shadow-xl  w-full bg-[#121212]">
      <div className="w-full container mx-auto p-4 md:py-8">
        <div className="sm:flex sm:items-start sm:justify-between">
          <div className="flex flex-col items-start justify-start gap-4">
            <a href="/" className="flex items-center">
              <img src="./images/logo.svg" className="h-10 mr-3" alt="Logo" />
            </a>
            <p className="text-lg font-normal text-gray-400 lg:text-xl md:w-5/6 w-full">
              Yin Hai international 715
              <br /> Yi Wu city Zhejiang province China
              <br /> Code city 322000
              <br /> Tel : (0579) 8585 3165
              <br /> Mobil : 18867577744
            </p>
          </div>
          <div className="flex flex-col items-start justify-start gap-4 ">
            <p className="text-lg font-normal text-base-100 lg:text-xl  w-full">
              Scan QR Code to Purchase Products
            </p>
            <img
              src="./images/qr-code.jpeg"
              alt="qr-code"
              className="w-32 h-32 object-fill"
            />
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />

        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="block text-sm text-gray-300 sm:text-center ">
            © {new Date().getFullYear()} Monease . All Rights Reserved.
          </span>
          <div className="flex mt-4 space-x-5 sm:justify-center sm:mt-0">
            <a href="#qrcode" className="text-gray-300 hover:text-primary">
              <BrandWhatsapp />
            </a>

            <a href="#qrcode" className="text-gray-300 hover:text-primary ">
              <BrandWechat />
            </a>

            <a
              href={`mailto:mustafa-alzubaidy@hotmail.com`}
              target="_blank"
              rel="noreferrer"
              className="text-gray-300 hover:text-primary "
            >
              <Mail />{" "}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
